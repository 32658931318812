export interface Event {
    id: number;
    exhibitionName: string;
    city: string;
    startDate: string;
    endDate: string;
    location: string;
    website: string;
}

export const EVENTS = [
    {
        id: 1,
        exhibitionName: "Basquiat x Warhol, à Quatre Mains",
        city: "Paris",
        startDate: "05-Apr-23",
        endDate: "28-Aug-23",
        location: "The Fondation Louis Vuitton",
        website: "https://www.fondationlouisvuitton.fr"
    },
    {
        id: 2,
        exhibitionName: "Manet/Degas",
        city: "Paris",
        startDate: "28-Mar-23",
        endDate: "23-Jul-23",
        location: "The Musée d’Orsay",
        website: "https://www.musee-orsay.fr"
    },
    {
        id: 3,
        exhibitionName: "Ramsès et l’or des Pharaons",
        city: "Paris",
        startDate: "07-Apr-23",
        endDate: "24-Jul-23",
        location: "The Grande Halle de la Villette",
        website: "https://www.expo-ramses.com/"
    },
    {
        id: 4,
        exhibitionName: "Sarah Bernhardt",
        city: "Paris",
        startDate: "14-Apr-23",
        endDate: "27-Aug-23",
        location: "The Petit Palais",
        website: "https://www.petitpalais.paris.fr/expositions/sarah-bernhardt"
    },
    {
        id: 5,
        exhibitionName: "Giovanni Bellini",
        city: "Paris",
        startDate: "03-Mar-23",
        endDate: "17-Jul-23",
        location: "The Jacquemart-André museum",
        website: "https://www.musee-jacquemart-andre.com/fr/giovanni-bellini"
    },
    {
        id: 6,
        exhibitionName: "Germaine Richier",
        city: "Paris",
        startDate: "01-Mar-23",
        endDate: "12-Jul-23",
        location: "The Centre Pompidou",
        website: "https://www.centrepompidou.fr"
    },
    {
        id: 7,
        exhibitionName: "Chagall, Paris-New York",
        city: "Paris",
        startDate: "17-Feb-23",
        endDate: "31-Dec-23",
        location: "Atelier des Lumières",
        website: "https://www.atelier-lumieres.com/en/chagall-paris-new-york"
    },
    {
        id: 8,
        exhibitionName: "Avant l’Orage",
        city: "Paris",
        startDate: "08-Feb-23",
        endDate: "11-Sep-23",
        location: "Bourse de Commerce – Pinault Collection",
        website: "https://www.pinaultcollection.com/fr/boursedecommerce/avant-lorage"
    },
    {
        id: 9,
        exhibitionName: "Matisse: Cahiers d’art, le tournant des années 30",
        city: "Paris",
        startDate: "01-Mar-23",
        endDate: "29-May-23",
        location: "Musée de l’Orangerie",
        website: "https://www.musee-orangerie.fr/en/whats-on/exhibitions/matisse-cahiers-dart-pivotal-1930s"
    },
    {
        id: 10,
        exhibitionName: "Picasso Celebration: Picasso in a new light",
        city: "Paris",
        startDate: "07-Mar-23",
        endDate: "27-Aug-23",
        location: "Musée National Picasso",
        website: "https://www.lonelyplanet.com/france/paris/marais-and-menilmontant/attractions/musee-national-picasso/a/poi-sig/372171/1322812"
    },
    {
        id: 11,
        exhibitionName: "Van Gogh at Auvers-sur-Oise: The last months",
        city: "Paris",
        startDate: "03-Oct-23",
        endDate: "31-Dec-23",
        location: "Musée d’Orsay",
        website: "https://www.musee-orsay.fr/en/node/218176"
    },
    {
        id: 12,
        exhibitionName: "David Hockney: Works from the Tate Collection",
        city: "Paris",
        startDate: "28-Jan-23",
        endDate: "28-May-23",
        location: "Le musée Granet",
        website: "https://www.museegranet-aixenprovence.fr/en/homepage?utm_medium=referral&utm_source=mutualart"
    },
    {
        id: 13,
        exhibitionName: "The Splendours of Uzbekistan's Oases",
        city: "Paris",
        startDate: "01-Jan-23",
        endDate: "06-Mar-23",
        location: "Louvre Paris",
        website: "https://www.louvre.fr/en/what-s-on/exhibitions/the-splendours-of-uzbekistan-s-oases"
    },
    {
        id: 14,
        exhibitionName: "Serge Gainsbourg Le mot exact",
        city: "Paris",
        startDate: "25-Jan-23",
        endDate: "05-May-23",
        location: "Centre Pompidou",
        website: "https://www.centrepompidou.fr/en/program/calendar/event/sERpg1R"
    },
    {
        id: 15,
        exhibitionName: "Musée Giuseppe Penone",
        city: "Paris",
        startDate: "01-Jan-23",
        endDate: "06-Mar-23",
        location: "Centre Pompidou",
        website: "https://www.centrepompidou.fr/en/program/calendar/event/dkG0Wgf"
    },
    {
        id: 16,
        exhibitionName: "Musée Evidence",
        city: "Paris",
        startDate: "01-Jan-23",
        endDate: "06-Mar-23",
        location: "Centre Pompidou",
        website: "https://www.centrepompidou.fr/en/program/calendar/event/1v0Iy51"
    },
    {
        id: 17,
        exhibitionName: "S.H. Raza (1922-2016)",
        city: "Paris",
        startDate: "15-Feb-23",
        endDate: "15-May-23",
        location: "Centre Pompidou",
        website: "https://www.centrepompidou.fr/en/program/calendar/event/FEzOAXj"
    },
    {
        id: 18,
        exhibitionName: "Novo Pilota, Amedeo Modigliani and his dealer Paul Guillaume",
        city: "Paris",
        startDate: "05-Mar-23",
        endDate: "05-Sep-23",
        location: "Musée de l’Orangerie",
        website: "https://www.musee-orangerie.fr/en/whats-on/exhibitions/novo-pilota-amedeo-modigliani-and-his-dealer-paul-guillaume"
    },
    {
        id: 19,
        exhibitionName: "The World of Banksy: The Immersive Experience",
        city: "Paris",
        startDate: "01-Jan-23",
        endDate: "31-Dec-23",
        location: "Musée Banksy",
        website: "https://theworldofbanksy.fr/?gclid=CjwKCAiAlp2fBhBPEiwA2Q10D3wo4dlbIS9irUwx6BPcjGShVyiQG_4bccJO-BII61G9sd4sA6YEHhoCjj8QAvD_BwE"
    },
    {
        id: 20,
        exhibitionName: "Fabrice Hyber The Valley",
        city: "Paris",
        startDate: "01-Jan-23",
        endDate: "30-Apr-23",
        location: "Fondation Cartier pour l'art contemporain",
        website: "https://www.fondationcartier.com/en/exhibitions/la-vallee"
    },
    {
        id: 21,
        exhibitionName: "Eternal Mucha",
        city: "Paris",
        startDate: "22-Mar-23",
        endDate: "20-Aug-23",
        location: "Grand Palais Immersive",
        website: "https://grandpalais-immersif.fr/en"
    },
    {
        id: 22,
        exhibitionName: "Naples in Paris",
        city: "Paris",
        startDate: "07-Jun-23",
        endDate: "31-Dec-23",
        location: "Louvre Paris",
        website: "https://www.louvre.fr/en/visit"
    },
    {
        id: 23,
        exhibitionName: "Léon Monet",
        city: "Paris",
        startDate: "15-Mar-23",
        endDate: "17-Jul-23",
        location: "The Musée du Luxembourg",
        website: "https://museeduluxembourg.fr/en"
    },
    {
        id: 24,
        exhibitionName: "Carnets Kanak\" at the Quai Branly",
        city: "Paris",
        startDate: "01-Jan-23",
        endDate: "12-Mar-23",
        location: "Jacques Chirac Museum",
        website: "https://www.quaibranly.fr/en/"
    },
    {
        id: 25,
        exhibitionName: "Harry Potter at Paris Expo",
        city: "Paris",
        startDate: "21-Apr-23",
        endDate: "01-Oct-23",
        location: "Porte de Versailles",
        website: "https://harrypotter-exposition.fr"
    },
    {
        id: 26,
        exhibitionName: "GOLD, The golds of Yves Saint Laurent",
        city: "Paris",
        startDate: "01-Jan-23",
        endDate: "14-May-23",
        location: "Musée Yves Saint Laurent Paris",
        website: "https://www.tiqets.com/fr/billets-musee-yves-saint-laurent-paris-l146384/?partner=Familinparis"
    },
    {
        id: 27,
        exhibitionName: "Picasso and Prehistory",
        city: "Paris",
        startDate: "08-Feb-23",
        endDate: "12-Jun-23",
        location: "Musée de l'Homme",
        website: "https://celebracionpicasso.es/en/evento/picasso-y-la-prehistoria"
    },
    {
        id: 28,
        exhibitionName: "Habibi, the revolutions of love",
        city: "Paris",
        startDate: "01-Jan-23",
        endDate: "19-Feb-23",
        location: "Institut du monde arabe",
        website: "https://www.imarabe.org/en"
    },
    {
        id: 29,
        exhibitionName: "Hyperrealism: This is not a body",
        city: "Paris",
        startDate: "01-Jan-23",
        endDate: "05-Mar-23",
        location: "Musée Maillol",
        website: "https://www.museemaillol.com/https://www.museemaillol.com"
    },
    {
        id: 30,
        exhibitionName: "Monet-Mitchell’ and the ‘Joan Mitchell Retrospective",
        city: "Paris",
        startDate: "01-Jan-23",
        endDate: "27-Feb-23",
        location: "The Fondation Louis Vuitton",
        website: "https://www.fondationlouisvuitton.fr"
    },
    {
        id: 31,
        exhibitionName: "Frida Kahlo, beyond appearances",
        city: "Paris",
        startDate: "01-Jan-23",
        endDate: "05-Mar-23",
        location: "Palais Galliera",
        website: "https://www.palaisgalliera.paris.fr/fr/expositions/frida-kahlo-au-dela-des-apparences"
    },
    {
        id: 32,
        exhibitionName: "Norman Foster",
        city: "Paris",
        startDate: "10-May-23",
        endDate: "07-Aug-23",
        location: "Centre Pompidou",
        website: "https://www.centrepompidou.fr/en/program/calendar/event/Lan1nnY"
    },
    {
        id: 33,
        exhibitionName: "L21 Broadcasting from Paris",
        city: "Paris",
        startDate: "13-Jan-23",
        endDate: "12-Feb-23",
        location: "JPS Gallery Paris",
        website: "https://jpsgallery.com"
    },
    {
        id: 34,
        exhibitionName: "VITAMINAS I Luciano Calderon",
        city: "Paris",
        startDate: "28-Jan-23",
        endDate: "26-Feb-23",
        location: "Ruttkowski;68",
        website: "https://www.ruttkowski68.com"
    },
    {
        id: 35,
        exhibitionName: "Hsia-Fei Chang - Les jeux sont faits, rien ne va plus",
        city: "Paris",
        startDate: "17-Jan-23",
        endDate: "25-Feb-23",
        location: "Galerie Laurent Godin",
        website: "https://www.laurentgodin.com/index.php"
    },
    {
        id: 36,
        exhibitionName: "Metallic Sunsets – Jonathan Monk",
        city: "Paris",
        startDate: "02-Feb-23",
        endDate: "25-Mar-23",
        location: "Dvir Gallery Paris",
        website: "http://dvirgallery.com"
    },
    {
        id: 37,
        exhibitionName: "Tableau de Guillaume Bottazzi à Paris",
        city: "Paris",
        startDate: "19-Mar-23",
        endDate: "31-Dec-23",
        location: "34-36 avenue Reille",
        website: "https://www.guillaume.bottazzi.orgme"
    },
    {
        id: 38,
        exhibitionName: "Art3f",
        city: "Paris",
        startDate: "08-Sep-23",
        endDate: "10-Sep-23",
        location: "Paris Expo Porte de Versailles",
        website: "https://www.art3f.fr/paris-fr"
    },
    {
        id: 39,
        exhibitionName: "ART PARIS 2023",
        city: "Paris",
        startDate: "30-Mar-23",
        endDate: "02-Apr-23",
        location: "Grand Palais Éphémère",
        website: "https://www.artparis.com/en/edition"
    },
    {
        id: 40,
        exhibitionName: "Paris+ par Art Basel",
        city: "Paris",
        startDate: "19-Oct-23",
        endDate: "22-Oct-23",
        location: "Grand Palais Éphémère",
        website: "https://www.davidzwirner.com/exhibitions/2023/yun-hyong-keun"
    },
    {
        id: 41,
        exhibitionName: "Yun Hyong-keun",
        city: "Paris",
        startDate: "07-Jan-23",
        endDate: "23-Feb-23",
        location: "David Zwirner Gallery",
        website: "https://www.davidzwirner.com/exhibitions/2023/yun-hyong-keun"
    },
    {
        id: 42,
        exhibitionName: "Lynne Cohen",
        city: "Paris",
        startDate: "12-Apr-23",
        endDate: "28-Aug-23",
        location: "Centre Pompidou",
        website: "https://www.centrepompidou.fr/en/program/calendar/event/4BLGsj2"
    },
    {
        id: 43,
        exhibitionName: "Georgia O’Keeffe: To See Takes Time",
        city: "New York",
        startDate: "09-Apr-23",
        endDate: "12-Aug-23",
        location: "Museum of Modern Art",
        website: "https://www.moma.org/"
    },
    {
        id: 44,
        exhibitionName: "Give Me a Sign: The Language of Symbols",
        city: "New York",
        startDate: "13-May-23",
        endDate: "31-Dec-23",
        location: "Cooper Hewitt Museum",
        website: "https://www.cooperhewitt.org/"
    },
    {
        id: 45,
        exhibitionName: "Van Gogh’s Cypresses",
        city: "New York",
        startDate: "22-May-23",
        endDate: "27-Aug-23",
        location: "Metropolitan Museum of Art",
        website: "https://www.metmuseum.org/exhibitions/listings/2023/van-gogh-cypresses"
    },
    {
        id: 46,
        exhibitionName: "This Is New York: 100 Years of the City in Art and Pop Culture",
        city: "New York",
        startDate: "19-May-23",
        endDate: "19-Jun-23",
        location: "Museum of the City of New York",
        website: "https://www.mcny.org/exhibition/new-york-100"
    },
    {
        id: 47,
        exhibitionName: "Andrea Fraser",
        city: "New York",
        startDate: "12-Jan-23",
        endDate: "25-Feb-23",
        location: "Marian Goodman Gallery",
        website: "https://www.galleriesnow.net/shows/andrea-fraser/"
    },
    {
        id: 48,
        exhibitionName: "Felix Gonzalez-Torres",
        city: "New York",
        startDate: "12-Jan-23",
        endDate: "25-Feb-23",
        location: "David Zwirner Gallary",
        website: "https://www.davidzwirner.com/exhibitions/2023/felix-gonzalez-torres"
    },
    {
        id: 49,
        exhibitionName: "Dan Flavin: Kornblee Gallery 1967",
        city: "New York",
        startDate: "10-Jan-23",
        endDate: "18-Mar-23",
        location: "David Zwirner Gallary",
        website: "https://www.davidzwirner.com/exhibitions/2023/dan-flavin-kornblee-gallery-1967"
    },
    {
        id: 50,
        exhibitionName: "Roma/New York, 1953–1964",
        city: "New York",
        startDate: "12-Jan-23",
        endDate: "25-Feb-23",
        location: "David Zwirner Gallary",
        website: "https://www.davidzwirner.com/exhibitions/2023/roma-new-york-1953-1964"
    },
    {
        id: 51,
        exhibitionName: "Sarah Sze: “Timelapse”",
        city: "New York",
        startDate: "21-Mar-23",
        endDate: "12-Sep-23",
        location: "Guggenheim Museum",
        website: "https://www.guggenheim.org/exhibitions"
    },
    {
        id: 52,
        exhibitionName: "Young Picasso in Paris",
        city: "New York",
        startDate: "12-May-23",
        endDate: "06-Aug-23",
        location: "Guggenheim Museum",
        website: "https://www.guggenheim.org/exhibition/young-picasso-in-paris"
    },
    {
        id: 53,
        exhibitionName: "Going Dark: The Contemporary Figure at the Edge of Visibility",
        city: "New York",
        startDate: "01-Sep-23",
        endDate: "31-Dec-23",
        location: "Guggenheim Museum",
        website: "https://www.guggenheim.org/exhibition/experimental-art-in-south-korea-1960s-70s"
    },
    {
        id: 54,
        exhibitionName: "Gego: Measuring Infinity",
        city: "New York",
        startDate: "29-Mar-23",
        endDate: "08-Sep-23",
        location: "Guggenheim Museum",
        website: "https://www.guggenheim.org/exhibition/gego-measuring-infinity"
    },
    {
        id: 55,
        exhibitionName: "Nick Cave: Forothermore",
        city: "New York",
        startDate: "01-Jan-23",
        endDate: "10-Apr-23",
        location: "Guggenheim Museum",
        website: "https://www.guggenheim.org/exhibition/nick-cave-forothermore"
    },
    {
        id: 56,
        exhibitionName: "Alex Katz: Gathering",
        city: "New York",
        startDate: "01-Jan-23",
        endDate: "20-Feb-23",
        location: "Guggenheim Museum",
        website: "https://www.guggenheim.org/exhibition/alex-katz-gathering"
    },
    {
        id: 57,
        exhibitionName: "Experimental Art in South Korea, 1960s-70s",
        city: "New York",
        startDate: "01-Sep-23",
        endDate: "31-Dec-23",
        location: "Guggenheim Museum",
        website: "https://www.guggenheim.org/exhibition/experimental-art-in-south-korea-1960s-70s"
    },
    {
        id: 58,
        exhibitionName: "Bob Thompson",
        city: "New York",
        startDate: "21-Apr-23",
        endDate: "08-Jul-23",
        location: "52 Walker",
        website: "https://www.52walker.com/"
    },
    {
        id: 59,
        exhibitionName: "Jaune Quick-to-See Smith: “Memory Map”",
        city: "New York",
        startDate: "19-Apr-23",
        endDate: "31-Aug-23",
        location: "Whitney",
        website: "https://whitney.org/exhibitions/jaune-quick-to-see-smith"
    },
    {
        id: 60,
        exhibitionName: "Hadi Falapishi: Almost There",
        city: "New York",
        startDate: "01-Apr-23",
        endDate: "30-Sep-23",
        location: "Whitney",
        website: "https://whitney.org/exhibitions/hadi-falapishi"
    },
    {
        id: 61,
        exhibitionName: "Henry Taylor: B Side",
        city: "New York",
        startDate: "04-Oct-23",
        endDate: "31-Dec-23",
        location: "Whitney",
        website: "https://whitney.org/exhibitions/henry-taylor"
    },
    {
        id: 62,
        exhibitionName: "Aliza Nisenbaum: “Queens, Lindo y Querido”",
        city: "New York",
        startDate: "23-Apr-23",
        endDate: "09-Oct-23",
        location: "Queens Museum",
        website: "https://queensmuseum.org/exhibition/aliza-nisenbaum-queens-lindo-y-querido/"
    },
    {
        id: 63,
        exhibitionName: "Refik Anadol: Unsupervised",
        city: "New York",
        startDate: "01-Apr-23",
        endDate: "05-Mar-23",
        location: "Museum of Modern Art",
        website: "https://www.moma.org/calendar/exhibitions/5535"
    },
    {
        id: 64,
        exhibitionName: "Visual Implications",
        city: "New York",
        startDate: "19-Jan-23",
        endDate: "12-Feb-23",
        location: "Chashama",
        website: "https://chashama.org/events/"
    },
    {
        id: 65,
        exhibitionName: "Closing Performances by HaeAhn Woo Kwon, and Ka Yan and Mountain",
        city: "New York",
        startDate: "17-Feb-23",
        endDate: "17-Feb-23",
        location: "International Studio & Curatorial Program",
        website: "https://iscp-nyc.org/event/undoing-exhibition-closing-performances-by-haeahn-woo-kwon-ka-yan-mountain"
    },
    {
        id: 66,
        exhibitionName: "MARIANO FERRANTE: Partitura",
        city: "New York",
        startDate: "01-Jan-23",
        endDate: "18-Feb-23",
        location: "Art Projects International",
        website: "https://artprojects.com/exhibitions/mariano-ferrante-partitura/"
    },
    {
        id: 67,
        exhibitionName: "Cécile Lobert. Unspoken.",
        city: "New York",
        startDate: "01-Jan-23",
        endDate: "18-Feb-23",
        location: "GALLERY SHRINE",
        website: "https://www.shrine.nyc/cecile-loberts-unspoken"
    },
    {
        id: 68,
        exhibitionName: "Emma Kohlmann. With Fire, I Love.",
        city: "New York",
        startDate: "18-Jan-23",
        endDate: "18-Feb-23",
        location: "Silke Lindner",
        website: "https://www.silkelindner.com"
    },
    {
        id: 69,
        exhibitionName: "Labyrinth of the Soul: Drawing 1965 - 2015",
        city: "New York",
        startDate: "07-Jan-23",
        endDate: "18-Feb-23",
        location: "Sean Kelly Gallery",
        website: "https://www.skny.com/"
    },
    {
        id: 70,
        exhibitionName: "Woven Stories",
        city: "New York",
        startDate: "04-Mar-23",
        endDate: "24-Mar-23",
        location: "Agora Gallery",
        website: "https://agora-gallery.com/ag-exhibitions/woven-stories-march-4-march-24-2023"
    },
    {
        id: 71,
        exhibitionName: "Pop Aesthetics: From Warhol to Hirst to KAWS",
        city: "New York",
        startDate: "01-Feb-23",
        endDate: "21-Feb-23",
        location: "Agora Gallery",
        website: "https://agora-gallery.com/ag-exhibitions/pop-aesthetics-from-warhol-to-hirst-to-kaws/"
    },
    {
        id: 72,
        exhibitionName: "Gordon Massman: Telegrams from the Sun",
        city: "New York",
        startDate: "01-Apr-23",
        endDate: "21-Apr-23",
        location: "Agora Gallery",
        website: "https://agora-gallery.com/ag-exhibitions/gordon-massman-telegrams-from-the-sun/"
    },
    {
        id: 73,
        exhibitionName: "CONSUELO JIMENEZ UNDERWOOD: ONE NATION UNDERGROUND",
        city: "New York",
        startDate: "01-Jan-23",
        endDate: "11-Feb-23",
        location: "Ruiz-Healy Art",
        website: "https://ruizhealyart.com/exhibitions/"
    },
    {
        id: 74,
        exhibitionName: "Tokyo Gendai Art Fair",
        city: "Tokyo",
        startDate: "07-Jul-23",
        endDate: "09-Jul-23",
        location: "Pacifico Yokohama",
        website: "https://tokyogendai.com"
    },
    {
        id: 75,
        exhibitionName: "Secrets of Important Cultural Properties:",
        city: "Tokyo",
        startDate: "17-Mar-23",
        endDate: "14-May-23",
        location: "the National Museum of Modern Art",
        website: "https://jubun2023.jp"
    },
    {
        id: 76,
        exhibitionName: "The Making of Munakata Shiko: Celebrating the 120th Anniversary of the Artist's Birth",
        city: "Tokyo",
        startDate: "18-Mar-23",
        endDate: "21-May-23",
        location: "Toyama Prefectural Museum of Art and Design",
        website: "https://tad-toyama.jp/en/exhibition-event/4700"
    },
    {
        id: 77,
        exhibitionName: "TEFAF Maastricht",
        city: "Maastricht",
        startDate: "11-Mar-23",
        endDate: "19-Mar-23",
        location: "MECC",
        website: "https://www.tefaf.com/fairs/tefaf-maastricht"
    },
    {
        id: 78,
        exhibitionName: "La Biennale di Venezia",
        city: "Venice",
        startDate: "20-May-23",
        endDate: "26-Nov-23",
        location: "CA’ GIUSTINIAN",
        website: "https://www.labiennale.org/en/architecture/2023"
    },
    {
        id: 79,
        exhibitionName: "Vermeer exhibition at the Rijksmuseum",
        city: "Amsterdam",
        startDate: "10-Feb-23",
        endDate: "04-Jun-23",
        location: "The Rijksmuseum",
        website: "https://www.rijksmuseum.nl/en/whats-on/exhibitions/vermeer"
    },
    {
        id: 80,
        exhibitionName: "Frieze Los Angeles",
        city: "Los Angeles",
        startDate: "16-Feb-23",
        endDate: "19-Feb-23",
        location: "Santa Monica Airport",
        website: "https://www.frieze.com/fairs/frieze-los-angeles"
    },
    {
        id: 81,
        exhibitionName: "Painting, Prayer, Text",
        city: "Los Angeles",
        startDate: "10-Feb-23",
        endDate: "15-Apr-23",
        location: "C L E A R I N G",
        website: "http://www.c-l-e-a-r-i-n-g.com/exhibitions/painting-prayer-text/"
    },
    {
        id: 82,
        exhibitionName: "Art Basel, Hong Kong",
        city: "Hong Kong",
        startDate: "23-Mar-23",
        endDate: "25-Mar-23",
        location: "Convention & Exhibition Centre",
        website: "https://www.artbasel.com"
    },
    {
        id: 83,
        exhibitionName: "Christopher Williams: R59C Keystone Shower Door — Chrome/Raindrop",
        city: "Hong Kong",
        startDate: "20-Jan-23",
        endDate: "18-Feb-23",
        location: "David Zwirner Gallery",
        website: "https://www.davidzwirner.com/exhibitions/2023/christopher-williams"
    },
    {
        id: 84,
        exhibitionName: "Art Basel",
        city: "Basel",
        startDate: "15-Jun-23",
        endDate: "18-Jun-23",
        location: "Messe Basel",
        website: "https://www.artbasel.com"
    },
    {
        id: 85,
        exhibitionName: "Design Miami / Basel",
        city: "Basel",
        startDate: "13-Jun-23",
        endDate: "18-Jun-23",
        location: "Messe Basel",
        website: "https://www.designmiami.com/"
    },
    {
        id: 86,
        exhibitionName: "Art Basel, Miami",
        city: "Miami",
        startDate: "07-Dec-23",
        endDate: "09-Dec-23",
        location: "Miami Beach Convention Center",
        website: "https://www.artbasel.com"
    },
    {
        id: 87,
        exhibitionName: "Superblue Miami",
        city: "Miami",
        startDate: "01-Jan-23",
        endDate: "31-Dec-23",
        location: "Superblue Miami",
        website: "https://www.superblue.com/Miami/"
    },
    {
        id: 88,
        exhibitionName: "Museum of Graffiti",
        city: "Miami",
        startDate: "01-Jan-23",
        endDate: "31-Dec-23",
        location: "Museum of Graffiti",
        website: "https://museumofgraffiti.com"
    },
    {
        id: 89,
        exhibitionName: "Salone del Mobile",
        city: "Milan",
        startDate: "18-Apr-23",
        endDate: "23-Apr-23",
        location: "Rho Fiera Milano fairgrounds",
        website: "https://www.salonemilano.it/en"
    },
    {
        id: 90,
        exhibitionName: "Edinburgh Fringe",
        city: "Edinburgh",
        startDate: "04-Aug-23",
        endDate: "28-Aug-23",
        location: "N\\A",
        website: "https://www.edfringe.com"
    },
    {
        id: 91,
        exhibitionName: "Cengiz Çekil: I Am Still Alive",
        city: "Istanbul",
        startDate: "02-Jan-23",
        endDate: "24-Sep-23",
        location: "Arter Musem",
        website: "https://www.arter.org.tr/EN/exhibitions/cengiz-cekil-i-am-still-alive/1193"
    },
    {
        id: 92,
        exhibitionName: "ARCOmadrid",
        city: "Madrid",
        startDate: "22-Feb-23",
        endDate: "26-Feb-23",
        location: "IFEMA",
        website: "https://www.ifema.es/en/arco/madrid"
    },
    {
        id: 93,
        exhibitionName: "Picasso vs. Velázquez",
        city: "Madrid",
        startDate: "01-Sep-23",
        endDate: "30-Nov-23",
        location: "Casa de Velázquez",
        website: "https://www.casadevelazquez.org/es"
    },
    {
        id: 94,
        exhibitionName: "ARCOLisboa",
        city: "Lisbon",
        startDate: "25-May-23",
        endDate: "28-May-23",
        location: "Feira Internacional de Lisboa",
        website: "https://www.ifema.es/en/arco/lisboa"
    },
    {
        id: 95,
        exhibitionName: "Annette Holdensen",
        city: "Copenhagen",
        startDate: "25-Feb-23",
        endDate: "01-Apr-23",
        location: "Christian Andersen",
        website: "https://christianandersen.net/Exhibitions/Yifan-Jiang-2022"
    },
    {
        id: 96,
        exhibitionName: "Circle and Square",
        city: "Brussels",
        startDate: "16-Feb-23",
        endDate: "08-Apr-23",
        location: "C L E A R I N G",
        website: "http://www.c-l-e-a-r-i-n-g.com/exhibitions/circle-and-square/"
    },
    {
        id: 97,
        exhibitionName: "Morandi Unlocked - Saturday Morning Tour",
        city: "London",
        startDate: "21-Jan-23",
        endDate: "21-Jan-23",
        location: "Estorick Collection of Modern Italian Art",
        website: "https://www.estorickcollection.com"
    },
    {
        id: 98,
        exhibitionName: "Giorgio Morandi: Masterpieces from the Magnani-Rocca Foundation",
        city: "London",
        startDate: "06-Jan-23",
        endDate: "28-May-23",
        location: "Estorick Collection of Modern Italian Art",
        website: "https://www.estorickcollection.com/exhibitions/giorgio-morandi-masterpieces-from-the-magnani-rocca-foundation"
    },
    {
        id: 99,
        exhibitionName: "Osvaldo Licini: Rebellious Angel",
        city: "London",
        startDate: "24-Jun-23",
        endDate: "27-Aug-23",
        location: "Estorick Collection of Modern Italian Art",
        website: "https://www.estorickcollection.com/exhibitions/osvaldo-licini-rebellious-angel"
    },
    {
        id: 100,
        exhibitionName: "Behrang Karimi: Dinge Weltweit",
        city: "London",
        startDate: "21-Jan-23",
        endDate: "05-Mar-23",
        location: "Maureen Paley",
        website: "https://www.newexhibitions.com/e/60436"
    },
    {
        id: 101,
        exhibitionName: "Abraham Kritzman. A Hand Beneath The Hills.",
        city: "London",
        startDate: "21-Jan-23",
        endDate: "25-Feb-23",
        location: "Danielle Arnaud",
        website: "http://www.daniellearnaud.com"
    },
    {
        id: 102,
        exhibitionName: "Artist Crit with Edwin Mingard",
        city: "London",
        startDate: "21-Jan-23",
        endDate: "21-Jan-23",
        location: "Chisenhale Gallery",
        website: "https://chisenhale.org.uk"
    },
    {
        id: 103,
        exhibitionName: "Richard Slee 'Sunlit Uplands'",
        city: "London",
        startDate: "21-Jan-23",
        endDate: "05-Mar-23",
        location: "Hales Gallery",
        website: "https://halesgallery.com"
    },
    {
        id: 104,
        exhibitionName: "FACES, A Journey Behind The Lens- Closing Reception",
        city: "London",
        startDate: "21-Jan-23",
        endDate: "21-Jan-23",
        location: "Oreofe Art Gallery, Workspace | Cannon Whar\nLondon",
        website: "https://www.oreofeartgallery.com"
    },
    {
        id: 105,
        exhibitionName: "Garth Lewis : The Small Stuff",
        city: "London",
        startDate: "21-Jan-23",
        endDate: "18-Feb-23",
        location: "Ken Artspace",
        website: "https://www.kenart.space"
    },
    {
        id: 106,
        exhibitionName: "PIT Chapter 2: Camberwell College of Arts - Third Year BA Fine Art: Drawing, Photography, Sculpture",
        city: "London",
        startDate: "21-Jan-23",
        endDate: "22-Jan-23",
        location: "Southwark Park Galleries - Lake Gallery",
        website: "https://southwarkparkgalleries.org"
    },
    {
        id: 107,
        exhibitionName: "Between Handrails' by Mirela Cabral",
        city: "London",
        startDate: "21-Jan-23",
        endDate: "11-Feb-23",
        location: "Kupfer",
        website: "https://kupfer.co"
    },
    {
        id: 108,
        exhibitionName: "Yayoi Kusama: Infinity Mirror Rooms",
        city: "London",
        startDate: "18-May-23",
        endDate: "28-Aug-23",
        location: "Tate Modern",
        website: "https://www.tate.org.uk/visit/tate-modern"
    },
    {
        id: 109,
        exhibitionName: "Place (Village), Rachel Whiteread",
        city: "London",
        startDate: "25-Mar-23",
        endDate: "01-Oct-23",
        location: "V&A Museum of Childhood",
        website: "https://www.vam.ac.uk/young"
    },
    {
        id: 110,
        exhibitionName: "Katie Spragg: Lambeth Wilds",
        city: "London",
        startDate: "01-Jan-23",
        endDate: "31-Dec-23",
        location: "The Garden Museum",
        website: "https://gardenmuseum.org.uk"
    },
    {
        id: 111,
        exhibitionName: "Heaven's Gate",
        city: "London",
        startDate: "22-Jan-23",
        endDate: "26-Feb-23",
        location: "he Now Building",
        website: "https://www.outernetglobal.com"
    },
    {
        id: 112,
        exhibitionName: "Unfamiliar",
        city: "London",
        startDate: "23-Jan-23",
        endDate: "28-Jul-23",
        location: "Royal College of Physicians Museum",
        website: "http://www.rcplondon.ac.uk/about-us/what-we-do/museum"
    },
    {
        id: 113,
        exhibitionName: "A life I once had",
        city: "London",
        startDate: "25-Jan-23",
        endDate: "30-Jan-23",
        location: "230 Portobello Road",
        website: "https://www.blueleopardpictures.com/art-news/nottinghillinthenineties"
    },
    {
        id: 114,
        exhibitionName: "Bone of my Bones, Flesh of my Flesh",
        city: "London",
        startDate: "24-Jan-23",
        endDate: "04-Mar-23",
        location: "Gathering",
        website: "https://www.gathering.london"
    },
    {
        id: 115,
        exhibitionName: "Albert Irvin: The Art Of Water",
        city: "London",
        startDate: "17-Jan-23",
        endDate: "04-Mar-23",
        location: "3812 Gallery",
        website: "https://www.3812gallery.com"
    },
    {
        id: 116,
        exhibitionName: "Mapping Landscapes",
        city: "London",
        startDate: "19-Jan-23",
        endDate: "18-Feb-23",
        location: "Stoppenbach & Delestre",
        website: "https://www.artfrancais.com"
    },
    {
        id: 117,
        exhibitionName: "Catherine Murphy",
        city: "London",
        startDate: "18-Jan-23",
        endDate: "25-Feb-23",
        location: "Sadie Coles HQ | Bury Street",
        website: "https://www.sadiecoles.com"
    },
    {
        id: 118,
        exhibitionName: "Frieze London",
        city: "London",
        startDate: "11-Oct-23",
        endDate: "15-Oct-23",
        location: "Regent’s Park",
        website: "https://www.frieze.com/fairs/frieze-london"
    },
    {
        id: 119,
        exhibitionName: "Alexander the Great: The Making of a Myth",
        city: "London",
        startDate: "01-Jan-23",
        endDate: "19-Feb-23",
        location: "The British Library",
        website: "https://www.bl.uk/events/alexander-the-great-the-making-of-a-myth"
    },
    {
        id: 120,
        exhibitionName: "Kamala Ibrahim Ishag: States of Oneness",
        city: "London",
        startDate: "01-Jan-23",
        endDate: "29-Jan-23",
        location: "Serpentine South Gallery",
        website: "https://www.serpentinegalleries.org/whats-on/kamala-ibrahim-ishag"
    },
    {
        id: 121,
        exhibitionName: "Soheila Sokhanvari: Rebel Rebel",
        city: "London",
        startDate: "01-Jan-23",
        endDate: "26-Feb-23",
        location: "Barbican Centre",
        website: "https://www.barbican.org.uk/whats-on/art-design"
    },
    {
        id: 122,
        exhibitionName: "Thinking for Printing",
        city: "London",
        startDate: "31-Jan-23",
        endDate: "05-Feb-23",
        location: "Bankside Gallery",
        website: "https://www.banksidegallery.com"
    },
    {
        id: 123,
        exhibitionName: "London Art Fair 2023: Encounters",
        city: "London",
        startDate: "18-Jan-23",
        endDate: "22-Jan-23",
        location: "Business Design Centre",
        website: "https://www.londonartfair.co.uk/fair-programme/encounters"
    },
    {
        id: 124,
        exhibitionName: "(It’s My Party) I Can Cry If I Want To",
        city: "London",
        startDate: "13-Jan-23",
        endDate: "02-Feb-23",
        location: "Guts Gallery",
        website: "https://gutsgallery.co.uk"
    },
    {
        id: 125,
        exhibitionName: "Ayo Akingbade in conversation with Noo Saro-Wiwa",
        city: "London",
        startDate: "02-Feb-23",
        endDate: "02-Feb-23",
        location: "Chisenhale Gallery",
        website: "https://chisenhale.org.uk"
    },
    {
        id: 126,
        exhibitionName: "Two By Two",
        city: "London",
        startDate: "02-Feb-23",
        endDate: "15-Feb-23",
        location: "Morrell House",
        website: "https://twotempleplace.org"
    },
    {
        id: 127,
        exhibitionName: "Transvangarde: Pushing Boundaries",
        city: "London",
        startDate: "02-Feb-23",
        endDate: "11-Mar-23",
        location: "October Gallery",
        website: "https://octobergallery.co.uk"
    },
    {
        id: 128,
        exhibitionName: "Yann Leto: Big City",
        city: "London",
        startDate: "02-Feb-23",
        endDate: "04-Mar-23",
        location: "JD Malat Gallery",
        website: "https://jdmalat.com"
    },
    {
        id: 129,
        exhibitionName: "POOL LIFE AND OTHER MEMORIES. Recent paintings by Alex Manolatos",
        city: "London",
        startDate: "03-Feb-23",
        endDate: "09-Feb-23",
        location: "Olympia Auctions",
        website: "https://www.olympiaauctions.com"
    },
    {
        id: 130,
        exhibitionName: "an act of god",
        city: "London",
        startDate: "12-Jan-23",
        endDate: "04-Feb-23",
        location: "James Freeman Gallery",
        website: "https://www.jamesfreemangallery.com"
    },
    {
        id: 131,
        exhibitionName: "Hilma Af Klint & Piet Mondrian",
        city: "London",
        startDate: "30-Apr-23",
        endDate: "03-Sep-23",
        location: "Tate Modern",
        website: "https://www.tate.org.uk/visit/tate-modern"
    },
    {
        id: 132,
        exhibitionName: "Dan Flavin: colored fluorescent light",
        city: "London",
        startDate: "12-Jan-23",
        endDate: "18-Feb-23",
        location: "David Zwirner Gallery",
        website: "https://www.davidzwirner.com/exhibitions/2023/dan-flavin-colored-fluorescent-light"
    },
    {
        id: 133,
        exhibitionName: "London Design Festival",
        city: "London",
        startDate: "16-Sep-23",
        endDate: "24-Sep-23",
        location: "Truman Brewery",
        website: "https://www.londondesignfestival.com"
    },
    {
        id: 134,
        exhibitionName: "The EY Exhibition: CEZANNE",
        city: "London",
        startDate: "01-Jan-23",
        endDate: "11-Mar-23",
        location: "Tate Modern",
        website: "https://www.tate.org.uk/whats-on/tate-modern/ey-exhibition-cezanne"
    },
    {
        id: 135,
        exhibitionName: "Spain and the Hispanic World",
        city: "London",
        startDate: "21-Jan-23",
        endDate: "10-Apr-23",
        location: "THE ROYAL ACADEMY OF ARTS",
        website: "https://www.royalacademy.org.uk/exhibition/spain-hispanic-world"
    },
    {
        id: 136,
        exhibitionName: "Omar El Lahib",
        city: "London",
        startDate: "26-Jan-23",
        endDate: "15-Mar-23",
        location: "SAATCHI YATES",
        website: "https://saatchiyates.com/exhibitions"
    },
    {
        id: 137,
        exhibitionName: "he Morgan Stanley Exhibition: Peter Doig",
        city: "London",
        startDate: "10-Feb-23",
        endDate: "29-Mar-23",
        location: "The Courtauld Gallery",
        website: "https://courtauld.ac.uk/whats-on/peter-doig"
    },
    {
        id: 138,
        exhibitionName: "Alice Neel: Hot Off The Griddle",
        city: "London",
        startDate: "16-Feb-23",
        endDate: "21-May-23",
        location: "Barbican Art Gallery",
        website: "https://www.barbican.org.uk/AliceNeel"
    },
    {
        id: 139,
        exhibitionName: "THE ROSSETTIS",
        city: "London",
        startDate: "06-Apr-23",
        endDate: "24-Sep-23",
        location: "Tate Britain",
        website: "https://www.tate.org.uk/whats-on/tate-britain/the-rossettis"
    },
    {
        id: 140,
        exhibitionName: "STYLE & SOCIETY: DRESSING THE GEORGIANS",
        city: "London",
        startDate: "21-Apr-23",
        endDate: "08-Oct-23",
        location: "The Queen’s Gallery",
        website: "https://www.rct.uk/collection/themes/exhibitions/style-society-dressing-the-georgians/the-queens-gallery-buckingham"
    },
    {
        id: 141,
        exhibitionName: "SAAC JULIEN: WHAT FREEDOM IS TO ME",
        city: "London",
        startDate: "26-Apr-23",
        endDate: "20-Aug-23",
        location: "Tate Britain",
        website: "https://www.tate.org.uk/whats-on/tate-britain/isaac-julien"
    },
    {
        id: 142,
        exhibitionName: "David Hockney, The Lightroom",
        city: "London",
        startDate: "22-Feb-23",
        endDate: "04-Jun-23",
        location: "12 Lewis Cubitt Square",
        website: "https://lightroom.uk"
    },
    {
        id: 143,
        exhibitionName: "Beyond the Streets London",
        city: "London",
        startDate: "17-Feb-23",
        endDate: "09-May-23",
        location: "Saatchi Gallery",
        website: "https://www.saatchigallery.com/exhibition/beyond_the_streets_london#:~:text=17%20February%20%2D%209%20May%202023"
    },
    {
        id: 144,
        exhibitionName: "Lynette Yiadom-Boakye: Fly in League with the Night",
        city: "London",
        startDate: "01-Jan-23",
        endDate: "26-Feb-23",
        location: "Tate Britain",
        website: "https://www.tate.org.uk/whats-on/tate-britain/lynette-yiadom-boakye"
    },
    {
        id: 145,
        exhibitionName: "",
        city: "London",
        startDate: "01-Jan-23",
        endDate: "12-Feb-23",
        location: "Royal Academy",
        website: "https://www.royalacademy.org.uk/exhibition/making-modernism"
    },
    {
        id: 146,
        exhibitionName: "Frameless Immersive Art Experience",
        city: "London",
        startDate: "01-Jan-23",
        endDate: "31-Dec-23",
        location: "6 Marble Arch",
        website: "https://marble-arch.london/shopping/frameless"
    },
    {
        id: 147,
        exhibitionName: "Barbara Chase-Riboud",
        city: "London",
        startDate: "01-Jan-23",
        endDate: "10-Apr-23",
        location: "Serpentine North Galler",
        website: "https://www.serpentinegalleries.org/whats-on/barbara-chase-riboud-infinite-folds"
    },
    {
        id: 148,
        exhibitionName: "Maria Bartuszová",
        city: "London",
        startDate: "01-Jan-23",
        endDate: "25-Jun-23",
        location: "Tate Modern",
        website: "https://www.tate.org.uk/whats-on/tate-modern/maria-bartuszova"
    },
    {
        id: 149,
        exhibitionName: "Soheila Sokhanvari: Rebel Rebel",
        city: "London",
        startDate: "01-Jan-23",
        endDate: "26-Feb-23",
        location: "Barbican Centre",
        website: "https://www.barbican.org.uk/whats-on/2022/event/soheila-sokhanvari-rebel-rebel"
    },
    {
        id: 150,
        exhibitionName: "Magdalena Abakanowicz",
        city: "London",
        startDate: "01-Jan-23",
        endDate: "21-Feb-23",
        location: "Tate Modern",
        website: "https://www.tate.org.uk/whats-on/tate-modern/magdalena-abakanowicz"
    },
    {
        id: 151,
        exhibitionName: "MARASIB",
        city: "Riyadh",
        startDate: "01-Feb-23",
        endDate: "05-Feb-23",
        location: "Gallery Naila",
        website: "http://gallerynaila.com/exhibitions/maha-alhazani"
    },
    {
        id: 152,
        exhibitionName: "Geometry and Art in the Modern Middle East",
        city: "Jeddah",
        startDate: "24-Jan-23",
        endDate: "20-Mar-23",
        location: "Jeddah Dome",
        website: "https://miskartinstitute.org/Exhibtions/Details?q=aWQ9Mzk%3D"
    },
    {
        id: 153,
        exhibitionName: "Islamic Arts Biennale",
        city: "Jeddah",
        startDate: "01-Jan-23",
        endDate: "01-Apr-23",
        location: "Hajj terminal",
        website: "https://linktr.ee/Biennale.SA"
    },
    {
        id: 154,
        exhibitionName: "AlUla Arts Festival",
        city: "AlUla",
        startDate: "16-Feb-23",
        endDate: "28-Feb-23",
        location: "AlUla",
        website: "https://www.experiencealula.com/en/alula-moments/alula-arts-festival"
    },
    {
        id: 155,
        exhibitionName: "FAME: ANDY WARHOL IN ALULA EXHIBITION",
        city: "AlUla",
        startDate: "17-Feb-23",
        endDate: "16-May-23",
        location: "Maraya",
        website: "https://www.experiencealula.com/en/discover-alula/arts-culture/fame-andy-warhol-in-alula"
    },
    {
        id: 156,
        exhibitionName: "100 BEST ARABIC POSTERS",
        city: "AlUla",
        startDate: "16-Feb-23",
        endDate: "16-May-23",
        location: "AlJadidah",
        website: "https://tickets.experiencealula.com/en/experiences/art-festival-expereince-pass",
    },
    {
        id: 157,
        exhibitionName: "Van Cleef & Arpels",
        city: "Riyadh",
        startDate: "19-Jan-23",
        endDate: "15-Apr-23",
        location: "Saudi National Museum",
        website: "https://www.vancleefarpels-event.com/exhibitions/time-nature-love-riyadh"
    }
];

export const CITIES = EVENTS.map((date) => date.city).filter(function(item, pos, self) {
    return self.indexOf(item) == pos;
}).sort((a,b) => a > b ? 1 : -1);
