import React, {useEffect, useRef} from 'react';
import {Calendar, Event, momentLocalizer} from 'react-big-calendar';
import moment from 'moment';
import {useReactToPrint} from 'react-to-print';
import {CITIES, EVENTS} from './data';
import './App.css';

const localizer = momentLocalizer(moment);

function App() {
    const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const [cities, _] = React.useState<string[]>(CITIES);
    const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const [city, setCity] = React.useState<string>("");
    const [month, setMonth] = React.useState<string>("");
    const [isGrid, setIsGrid] = React.useState<boolean>(true);
    const [eventsList, setEventsList] = React.useState<Event[]>(EVENTS.map((date) => {
        return {
            title: date.exhibitionName,
            start: moment(date.startDate).toDate(),
            end: moment(date.endDate).toDate(),
            website: date.website,
        }
    }));


    useEffect(() => {
        if (city || month) {
            const filteredEvents = EVENTS.filter((event) => {
                return event.city === city && moment(event.startDate).format('MMMM') === month;
            });
            setEventsList(filteredEvents.map((date) => {
                return {
                    title: date.exhibitionName,
                    start: moment(date.startDate).toDate(),
                    end: moment(date.endDate).toDate(),
                    website: date.website,
                }
            }));
        }
    }, [city, month]);


    return (
        <div className="container p-5">
            <h1 className="text-center mb-5">Exhibition Calendar</h1>
            <div className="d-flex flex-row mb-5 justify-content-center">
                <div className="dropdown">
                    <button className="btn btn-light dropdown-toggle rounded-0 border" type="button"
                            data-bs-toggle="dropdown" aria-expanded="false">
                        {city || "Cities"}
                    </button>
                    <ul className="dropdown-menu">
                        {cities.map((city, index) => {
                            return (
                                <li key={index}><a className="dropdown-item" href="#" onClick={() => {
                                    setCity(city)
                                }}>{city}</a></li>
                            )
                        })
                        }
                    </ul>
                </div>
                <div className="dropdown">
                    <button className="btn btn-light dropdown-toggle rounded-0 border" type="button"
                            data-bs-toggle="dropdown" aria-expanded="false">
                        {month || "Months"}
                    </button>
                    <ul className="dropdown-menu">
                        {MONTHS.map((month, index) => {
                            return (
                                <li key={index}><a className="dropdown-item" href="#" onClick={() => {
                                    setMonth(month)
                                }}>{month}</a></li>
                            )
                        })
                        }
                    </ul>
                </div>
            </div>
            <div className="btn-group btn-group-toggle mb-3" data-toggle="buttons">
                <label className="btn btn-light">
                    <input type="radio" autoComplete="off" checked={isGrid} onClick={() => {
                        setIsGrid(!isGrid)
                    }}/> Grid
                </label>
                <label className="btn btn-light">
                    <input type="radio" autoComplete="off" checked={!isGrid} onClick={() => {
                        setIsGrid(!isGrid)
                    }}/> Calendar
                </label>
            </div>

            {/*<button className="btn btn-primary rounded-0" onClick={handlePrint}>Print this out!</button>*/}
            <div ref={componentRef}>
                {city && month &&
                    <>
                        {isGrid &&
                            <div className='container'>
                                <div className="row">
                                    {eventsList.map((event: any, index) => {
                                        return (
                                            <div key={index} className="col-md-4">
                                                <div className="card mb-4 shadow-sm">
                                                    <div className="card-body">
                                                        <img src="https://picsum.photos/200" className="card-img-top"
                                                             alt='event image'/>
                                                        <p className="card-text">{event.title}</p>
                                                        <div
                                                            className="d-flex justify-content-between align-items-center">
                                                            <div className="btn-group">
                                                                <button type="button"
                                                                        className="btn btn-sm btn-outline-secondary"
                                                                        onClick={() => {
                                                                            window.open(event.website, '_blank');
                                                                        }}>View
                                                                </button>
                                                            </div>
                                                            <small
                                                                className="text-muted">{moment(event.start).format('DD MMM YYYY')}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                {eventsList.length === 0 && <div className="text-center">No events found</div>}
                            </div>

                            || <Calendar
                                localizer={localizer}
                                events={eventsList}
                                startAccessor="start"
                                endAccessor="end"
                                style={{height: 500}}
                                onSelectEvent={(event: any) => {
                                    console.log(event);
                                    window.open(event.website, '_blank');
                                }}
                            />}
                    </>
                    || <div className="text-center">Please select a city and a month</div>}
            </div>

        </div>
    );
}

export default App;
